import React from 'react';
import classes from './HomePlatform.module.scss';


const HomePlatform = props => {
    return (
        <>
            <h2>Platform</h2>

            <div className={classes.benefitBox + ' ' + classes.firstChild}>
                <div className={classes.benefitBoxList}>
                    <h3>Home Page</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-map-marker-alt"></i></td>
                                <td>We detect user's location to display listings from his city / region. Location data is not connected to his account so even if the user is not logged in he will see listings that are close to him.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-search"></i></td>
                                <td>Dedicated category search allows users to find what they need straight from the home page of the website or home screen of the Followone App.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-home.a4dcdb328601ea3e0407.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={`${classes.benefitBoxList} ${classes.benefitBoxListRight}`}>
                    <h3>Category Page</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-search"></i></td>
                                <td>Dedicated category search combined with most used filters allows the user to quickly find what he’s looking for.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-search-plus"></i></td>
                                <td>Advanced search allows to user to narrow the search results by using keywords, dealer name, location, filters & more.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-search"></i></td>
                                <td>Sidebar keywords search allows the user to search by keywords or dealership name and filters are another convenient way to narrow down search results.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-plus-circle"></i></td>
                                <td>Post 1.5k to 1M Listings with our Premium or Enterprise subscription packages.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-category.2e2e2be4250cf8c987f7.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={classes.benefitBoxList}>
                    <h3>Listing Preview</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-money-bill-alt"></i></td>
                                <td>FSP (Followone Suggested Price), available only on Followone Auto,  gives our users a starting point to negociate.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-heart"></i></td>
                                <td>Users can like your listings boosting your credibility.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-eye"></i></td>
                                <td>A view is added after a users is on the listing page for at least 10 seconds, boosting popularity.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-share-alt"></i></td>
                                <td>Share the listing on Facebook, Twitter, Pinterest, WhatsApp, Viber, Telegram or copy the link.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-bookmark"></i></td>
                                <td>Users can bookmark their favorites items and see them later in their favorites list available from the profile page.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-listing-preview.859799ce5d6b9936f252.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={`${classes.benefitBoxList} ${classes.benefitBoxListRight}`}>
                    <h3>Listing Detail Page</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-search"></i></td>
                                <td>Dealership location map allows the user to easily get directions.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-images"></i></td>
                                <td>The image slider allows the user to scroll through all images available for the listing.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-money-bill-alt"></i></td>
                                <td>FSP gives our users a starting point to negociate.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-user-chart"></i></td>
                                <td>Boost your credibility & popularity with likes, views, shares and favorites.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-user-tie"></i></td>
                                <td>One click/tap contact includes: Followone chat, phone, email, directions and link to author’s website. </td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-chart-line"></i></td>
                                <td>Linking back to user’s websites boost its SEO and Google Rankings. </td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-browser"></i></td>
                                <td>Modern, easy to use interface allows the user to quickly scan the listing details in order to make a buying decision.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-list-ul"></i></td>
                                <td>Options and features lists, ordered alphabetically allow to user to easily find the ones he’s interested in. </td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-images"></i></td>
                                <td>The footer page photo gallery allows the user to see all photos that pertain to current listing at once.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-listing-detail-page.54724d24b58c347e0b9c.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={classes.benefitBoxList}>
                    <h3>Listing Detail Sidebar</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-user-tie"></i></td>
                                <td>Listing owner’s branding, logo, name and link to prof ile page.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-phone-office"></i></td>
                                <td>Listing owner’s contact information, category, and other details.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-plus-circle"></i></td>
                                <td>Auto listings for dealers are managed by Followone, but users can add new listings and manage them manually.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-barcode-alt"></i></td>
                                <td>Similar items section contains two more listings by the same business, displayed for free. </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-listing-detail-sidebar.35d7538cdc64b5fac754.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={`${classes.benefitBoxList} ${classes.benefitBoxListRight}`}>
                    <h3>Profile Page</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-images"></i></td>
                                <td>The user can add a cover image or an advertising banner.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-user-tie"></i></td>
                                <td>The user can upload his logo or a profile photo.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-chart-network"></i></td>
                                <td>Followone button allows users to send a follow request and build your community.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-cog"></i></td>
                                <td>The profile navigation can be customized in the Listing Settings.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-money-bill-alt"></i></td>
                                <td>Sell products, cars, real estate residential & commercial properties, business opportunities, foods and travel packages.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-users"></i></td>
                                <td>Build your community, share events, photos, videos & blogs.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-briefcase"></i></td>
                                <td>Grow your community by sharing job opportunities.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-wrench"></i></td>
                                <td>Post your services and connect to our entire community.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-shield-check"></i></td>
                                <td>Boost credibility by beeing a Followone Accredited Business.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-info-circle"></i></td>
                                <td>The about section provides quick contact info and business description.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-star"></i></td>
                                <td>Build credibility and popularity with Followone reviews.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-chart-line"></i></td>
                                <td>Profile stats show’s the popularity of your profile: no. of followers, no. of listings and no. of prof ile views.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-profile-page.eb6ef6bf7e079d56d3fa.jpg" alt="Invest by Followone" />
                </div>
            </div>


            <div className={classes.benefitBox}>
                <div className={classes.benefitBoxList}>
                    <h3>Profile Listing Page</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-search"></i></td>
                                <td>The profile search allows the user to search inside your inventory.</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-plus-circle"></i></td>
                                <td>Post your entire inventory*, up to 1M listings and build your online store or showroom. </td>
                            </tr>
                        </tbody>
                    </table>

                    <p className="note">*For Auto & Real Estate we add, maintain and update your inventory.</p>
                </div>

                <div className={classes.benefitBoxImg}>
                    <img src="https://followone.com/static/media/about-benefits-profile-listing-page.c0dec495760d0de4cb40.jpg" alt="Invest by Followone" />
                </div>
            </div>
        </>
    )
}

export default HomePlatform;
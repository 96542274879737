import React, { useContext } from 'react';

import classes from './HomeHeader.module.scss';

import Image1 from '../../../assets/investors_img_1.jpg';
import Logo from '../../../shared/components/UIElements/Logo';
import Button from '../../../shared/components/UIElements/Button';
import { AuthContext } from '../../../shared/context/auth-context';

const HomeHeader = props => {

    const auth = useContext(AuthContext);

    return (
        <div className={classes.home_header} style={{backgroundImage: `url(${Image1})`}}>
            {/* <img src={Image1} alt="Invest by Followone" />  */}

            <div className={classes.home_header_content}>
                <div className={classes.home_header_content_logo}>
                    <Logo />
                </div>


                <p className={classes.cta}>Followone is a SaaS technology platform with a focus on AI, designed to vertically integrate industries and streamline transactions across sectors like Auto, Real Estate, Healthcare, and E-commerce, with planned expansions into Food, Services, Jobs, and Travel — accelerating growth by centralizing engagement on one intelligent platform.</p>


                {/* <p className={classes.home_header_content_cta}>Buy Stocks in Pre IPO in Followone Inc. (FO)* <br /> <span style={{fontSize: '1.2rem', display: 'block', marginTop: '4px'}}>* Symbol Reserved with Nasdaq</span></p> */}
                <p className={classes.home_header_content_cta}>Followone Inc. <br /> <span style={{fontSize: '1.6rem', display: 'block', marginTop: '4px'}}>a Social-Business Marketing Platform</span></p>


                <div className={classes.home_header_content_buttons}>
                    <div className={ classes.lgBtn }>
                        {!props.isAuth && <Button to="/create-account">Start Investing Today</Button>}
                    </div>

                    <div className={ classes.linkBtn }>
                        {!props.isAuth && <Button onClick={props.openSignInModalHandler} color="transparent">Have an account? Sign In</Button>}
                    </div>

                    {/* <div className={ classes.lgBtn }>
                        {props.isAuth && <Button color="green" to="/buy-shares">Buy Shares</Button>}
                    </div> */}

                    <div className={ classes.linkBtn }>
                        {props.isAuth && <Button color="transparent" onClick={auth.logout}>Sign Out</Button>}
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default HomeHeader;